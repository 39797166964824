let xlsx = require("xlsx");
import { export_json_to_excel } from "./Export2Excel.js";
export default {
  methods: {
    // 导出
    async exportElxs(data) {
      export_json_to_excel(data);
    },

    // 导入
    httpRequest(e,callback) {
      let file = e.file; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }

      const fileReader = new FileReader(); // 读取文件
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = xlsx.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];
          // 转换成json数据
          const exl = xlsx.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          // 打印 ws 就可以看到读取出的表格数据
          callback(exl);
        } catch (e) {
          callback(null);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
  }

}